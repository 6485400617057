<template>
  <div class="app-datetime-picker">
    <AppDatePicker v-model="date" :hasError="hasError" :placeholder="placeholderForDate" @input="handleInput" />
    <AppTimePicker v-model="time" :hasError="hasError" :placeholder="placeholderForTime" @input="handleInput" />
  </div>
</template>

<script>
import AppDatePicker from '@/components/AppDatePicker.vue';
import AppTimePicker from '@/components/AppTimePicker.vue';

export default {
  props: {
    value: [Date, String],
    placeholderForDate: {
      type: String,
      default: ' ',
    },
    placeholderForTime: {
      type: String,
      default: ' ',
    },
    hasError: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    AppDatePicker,
    AppTimePicker,
  },
  data: () => ({
    date: null,
    time: null,
  }),
  watch: {
    value(val) {
      this.formatValueFromInput(val)
    },
  },
  mounted() {
    this.formatValueFromInput(this.value)
  },
  methods: {
    validate() {
      this.$refs.date.validate()
      this.$refs.time.validate()
    },
    formatValueFromInput(value) {
      if ([null, undefined].includes(value)) {
        return
      } else if (value instanceof Date) {
        this.date = value
        this.time = value
      } else if (typeof value == 'string') {
        const [date, time] = value.split(' ')
        this.date = date
        this.time = time
      }
    },
    handleInput() {
      if ([this.date, this.time].includes(null)) {
        return
      }

      this.$emit('input', `${this.date} ${this.time}`)
    },
  },
};
</script>

<style lang="scss" scoped>
@use "@/assets/scss/base-colors.scss";

.app-datetime-picker {
  display: flex;
  margin: -8px;

  > * {
    flex: 1 1 50%;
    margin: 8px;
  }
}
</style>

<template>
  <vue-timepicker
    :placeholder="placeholder"
    format="HH:mm"
    v-model="time"
    :input-class="[ 'form-control' ]"
    class="app-time-picker"
    :class="{ 'app-time-picker--is-invalid': hasError }"
    @input="handleInput"
  ></vue-timepicker>
</template>

<script>
import parseHourAndMinute from "@/utils/parseHourAndMinute";
import deepGet from "lodash/get";
import VueTimepicker from "vue2-timepicker";

export default {
  props: {
    value: [Date, String],
    placeholder: {
      type: String,
      default: ' ',
    },
    hasError: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    VueTimepicker,
  },
  data: () => ({
    time: {
      HH: null,
      mm: null,
    },
  }),
  watch: {
    value(val) {
      this.formatValueFromInput(val)
    },
  },
  mounted() {
    this.formatValueFromInput(this.value)
  },
  methods: {
    deepGet,
    formatValueFromInput(value) {
      if ([null, undefined].includes(value)) {
        // catched but do nothing, can be altered to provide default value
      } else {
        try {
          const [hour, minute] = parseHourAndMinute(value)
          this.time = { HH: hour, mm: minute }
        } catch (e) {
          console.warn('[AppTimePicker] Received invalid time format:', value)
        }
      }
    },
    handleInput(value) {
      if (value.HH && value.mm) {
        this.$emit('input', `${value.HH}:${value.mm}`)
      }

      if (!value.HH && !value.mm) {
        this.$emit('input', null)
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@use "@/assets/scss/base-colors.scss";

::v-deep.app-time-picker {
  .form-control {
    border-radius: 5px;
    font-size: inherit;
  }

  &--is-invalid {
    .form-control {
      border: 1px solid map-get(base-colors.$theme-colors, danger);
    }
  }
}
</style>

<template>
  <div class="carousel-selection">
    <div class="carousel-selection-field" @click="handleFieldIsClicked">
      {{ textForField }}
    </div>

    <b-modal ref="carousel" centered hide-header hide-footer>
      <template #default="{ close }">
        <div class="carousel-selection-modal" :style="colorObject">
          <button class="carousel-selection-modal__close" @click="close()">
            <img src="@/assets/images/close.svg" />
          </button>

          <swiper :options="swiperOption">
            <swiper-slide v-for="(candidate, idx) in candidates" :key="idx">
              <div class="badge__detail__hero">
                <div class="badge__detail__hero__img">
                  <img
                    v-show="candidate.loaded"
                    :src="candidate.image"
                    @load="candidate.loaded = true"
                    class="img-fluid"
                  />

                  <div v-if="!candidate.loaded" class="text-center p-4">
                    <b-spinner variant="secondary"></b-spinner>
                  </div>
                </div>
              </div>
              <div class="py-3 border-bottom px-4">
                <div class="badge__detail__hero__title mt-2">{{ candidate.title }}</div>
                <div v-html="candidate.description"></div>
              </div>
              <div class="py-3">
                <SharedButton class="s-btn-bg-primary" @click="handleCandidateIsSelected(candidate); close()">選擇</SharedButton>
              </div>
            </swiper-slide>
          </swiper>

          <div class="swiper-button-prev"></div>
          <div class="swiper-button-next"></div>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import SharedButton from "@/components/Page/Liff/Shared/Button.vue";
import themeColor from "@/mixins/liff/themeColor";
import deepGet from "lodash/get";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";

export default {
  mixins: [themeColor],
  components: {
    SharedButton,
    Swiper,
    SwiperSlide,
  },
  props: {
    value: [String, Number, Array, Object],
    placeholder: {
      type: String,
      default: '請選擇',
    },
    fetchCandidates: { // format: { title, description, image, value }
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      candidates: [],
      selectedCandidate: null,
      swiperOption: {
        loop: true,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
    }
  },
  computed: {
    textForField() {
      return deepGet(this.selectedCandidate, 'title') || deepGet(this.value, 'text') || this.placeholder
    },
  },
  async mounted() {
    this.candidates = (await this.fetchCandidates()).map(candidate => ({ ...candidate, loaded: false }))
  },
  methods: {
    handleFieldIsClicked() {
      this.$refs.carousel.show()
    },
    handleCandidateIsSelected(candidate) {
      this.selectedCandidate = candidate

      this.$emit('input', candidate.value)
    },
  }
}
</script>

<style lang="scss" scoped>
.carousel-selection-field {
  width: 100%;
  border: 1px solid #e5e5ea;
  border-radius: 5px;
  padding: 8px 12px;
  font-weight: 500;
  font-size: 18px;
  color: var(--liff-primary_text_color);
  cursor: pointer;

  &:focus {
    border-color: var(--liff-primary_text_color);
  }
}

.carousel-selection-modal {
  &__close {
    position: absolute;
    top: -3rem;
    left: 50%;
    transform: translateX(-50%);
    border: none;
    border-radius: 100%;
    aspect-ratio: 1;
    padding: 10px;
    line-height: 0;
  }

  .swiper-button-prev,
  .swiper-button-next {
    padding: 0 8px;
    background: white;
    border-radius: 40px;
    position: absolute;
    bottom: 12px;
    z-index: 1;
    aspect-ratio: 1;
    width: 40px;
    height: 40px;
    box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);

    &::after {
      color: black;
      font-size: 18px;
    }
  }

  .swiper-button-prev {
    left: -5px;
  }
  .swiper-button-next {
    right: -5px;
  }
}

::v-deep .modal-content {
  border-radius: 10px;
  font-size: 15px;
}
</style>

<style>
.modal-backdrop {
  opacity: 0.5;
}
</style>

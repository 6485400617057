import { getHours, getMinutes } from "date-fns";

function isNumeric (value) {
  return false === isNaN(value) && false === isNaN(parseFloat(value))
}

export default function (value) {
  if (value instanceof Date) {
    return [
      String(getHours(value)).padStart(2, "0"),
      String(getMinutes(value)).padStart(2, "0"),
    ]
  } else if (typeof value === 'string') {
    let timeString
    if (value.includes(' ')) {
      timeString = value.split(' ').find(splitedStr => splitedStr.includes(':'))
    } else {
      timeString = value
    }
    const segments = timeString.split(':')
    if (false === [2, 3].includes(segments.length) || false === isNumeric(segments[0]) || false === isNumeric(segments[1])) {
      throw new Error(`Invalid time format received`)
    }

    return [ segments[0], segments[1] ]
  } else {
    throw new Error(`Invalid time format received`)
  }
}

<template>
  <div class="dynamic-checkbox form-check">
    <input
      type="checkbox"
      class="form-check-input"
      :name="name"
      :checked="modelValue.includes(value)"
      @change="
        $emit(
          'input',
          modelValue.includes($event.target.value)
            ? modelValue.filter((v) => v !== $event.target.value)
            : modelValue.concat($event.target.value)
        )
      "
      :id="id"
      :value="value"
    />
    <label class="form-check-label" :for="id">
      <slot />
    </label>
  </div>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: Array,
      default: () => [],
    },
    id: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    value: {
      type: String,
    },
  },
  model: {
    prop: "modelValue",
    event: "input",
  },
};
</script>

<style scoped lang="scss">
.dynamic-checkbox {
  display: flex;
  align-items: center;
  padding: 0.5rem !important;
  border: dotted 1px var(--liff-button-color) !important;
  border-radius: 3px;
  margin-bottom: 10px;

  &:has(.form-check-input:checked) {
    border-style: solid;
    background-color: var(--liff-button-color);
    color: white;
  }

  .form-check-input {
    width: 18px;
    height: 18px;
    -webkit-appearance: none;
    border-radius: 2px;
    outline: none;
    border: 3px solid var(--liff-button-color);
    opacity: 0.5;

    &:before {
      content: "";
      display: block;
      width: 6px;
      height: 10px;
      margin: auto;
    }

    &:checked:before {      
      border: solid var(--liff-button-color);
      border-width: 0 3px 3px 0;
      transform: rotate(45deg);
    }

    &:checked {
      border-color: white;
      opacity: 1;
      background-color: white;
    }
  }

  .form-check-label {
    padding-left: 25px;
    font-size: 18px;
    flex: 1 1 auto;
    cursor: pointer;
  }
}
</style>
